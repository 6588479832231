body {
  background: #f5f5f5;
  font-family: "Roboto", sans-serif;
  font-size: 15px;

  @media only screen and (max-width: 575px) {
    font-size: 14px;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #d5d5d5;
}

a,
a:hover,
a:focus {
//   color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.navbar-user-details {
  p {
    // color: #fff;

    &:last-child {
      opacity: 0.5;
    }
  }
}

table {
  td {
    padding: 0.5rem;
    min-width: 1rem;
    max-width: 200px;
    word-break: break-all;
  }
}
