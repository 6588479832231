.wrapper {
  width: 100%;
  display: flex;
  overflow: hidden;
  align-items: stretch;
    padding-bottom: 80px;
  .sidebar {
    min-width: $sidebar-width;
    max-width: $sidebar-width;
    margin-left: -$sidebar-width;
    transition: all 0.5s;

    &.is-open {
      margin-left: 1rem;
      transition: 0.5s;

      @media only screen and (max-width: 575px) {
        min-width: 100%;
        max-width: 100%;
        transition: all 0.5s, height 0s;

        .fixed {
          min-width: 100%;
          max-width: 100%;
        }

        .sidebar-header .times {
          display: unset;
        }
      }
    }

    .sidebar-header {
      width: 100%;
      height: 100px;
      color: $primary;
      text-align: center;
      img {
        width: 100%;
        height: 100%;
        max-width: 220px;
      }

      .times {
        position: relative;
        float: right;
        margin: 0.5em;
        font-size: 2rem;
        cursor: pointer;
        display: none;
      }
    }

    .fixed {
      // z-index: 99999;
      min-width: $sidebar-width;
      max-width: $sidebar-width;
      position: fixed;
      top: 1rem;
      bottom: 1rem;
      overflow-y: auto;
      border-radius: 4px;
      background: $sidebar-color;
      -webkit-overflow-scrolling: auto;
      transition: 0.5s;
    }

    .nav-item {
      border-radius: 4px;

      &.menu-open {
        // background: $sidebar-amenu;

        .dropdown-toggle::after {
          -webkit-transform: rotate(90deg);
          -moz-transform: rotate(90deg);
          -ms-transform: rotate(90deg);
          -o-transform: rotate(90deg);
          transform: rotate(90deg);
        }
      }

      .nav-link {
        padding: 0.5rem 1rem;
        border-radius: 4px;
        margin-top: 4px;
        color: #000;

        &:hover {
          padding-left: 1.3rem;
        }

        &.active {
          // background: $sidebar-alink;
          color: $primary;

          &::before {
            border-color: $primary;
          }
        }

        &.dropdown-toggle {
          cursor: pointer;
          position: relative;

          &::after {
            border: 0;
            font-size: 12px;
            content: "\276F";
            position: absolute;
            right: 11px;
            top: 11px;

            transition: ease-in 0.2s;
          }
        }
      }
    }

    .items-menu {
      color: #fff;

      .nav-link {
        &::before {
          content: "";
          width: 8px;
          height: 8px;
          border-radius: 50%;
          border: 0.1rem solid #000;
          display: inline-block;
          margin-right: 10px;
          margin-left: 3px;
        }
      }
    }
  }

  .content {
    width: 100%;
    margin: 1rem;
    min-height: 100vh;

    .navbar {
      background: $primary;
      border-radius: 4px;
      border-bottom: 1px solid $border-color;

      .nav-link {
        margin-left: 1.5rem;
      }
    }

    .main {
      background: #fff;
      border-radius: 4px;
    }

    &.is-open {
      @media only screen and (max-width: 575px) {
        overflow-y: hidden;
        -webkit-overflow-scrolling: none;
        margin-left: 100%;
      }
    }

    .page-header {
      h1 {
        font-size: 1.8rem;
      }
    }

    @media screen and (min-device-width: 768px) {
      .page-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}

.create-appeal {
    background-image: url('../../media/image/girl-computer.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: blur(8px);
    -webkit-filter: blur(8px);
    width: 100%;
    min-height: 100vh;
    height: 100%;
    // background-color: red;
    // backdrop-filter: blur(150px);
}

.create-appeal-form{
    backdrop-filter: blur(150px);
    padding: 30px;
    max-width: 1240px;
    background-color: rgba(189, 188, 188, 0.608);
}

.step {
    width: 30px;
    height: 30px;
    margin: 0 2px;
    background-color: #d6d3d3;
    border: none;
    border-radius: 50%;
    display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: smaller;
}
  
.step-line{ 
    width: 20px;
    height: 2px;
    border:none;
    background-color: rgb(182, 182, 182);
}
/* Mark the active step: */
.step-failed{

}

.step-succeeded{
    background-color: rgb(91, 91, 215);
}

.step-failed{
    background-color: rgb(217, 90, 90);
}
/* Mark the steps that are finished and valid: */

.login-form{
    margin-top: 200px;
    // width: 700px;
}

.login {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  .login-wrapper {
    background-color: rgba(234, 228, 228, 0.6);
    position: relative;
    min-width: 300px;

    border-radius: 10px;
    margin-top: 100px;

    .form-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      margin: 0 auto;
      margin-bottom: 10px;
    }

    .eye-icon {
      position: absolute;
      right: 4%;
      top: 50px;
      cursor: pointer;
    }
  }
}

hr {
  background: $primary;
}

.page-title {
  font-size: 1.8rem ;
  font-weight: bold;
}

.modal-content {
  border-radius: 8px ;

  .modal-header {
    border-bottom: none;
  }

  .modal-footer {
    border-top: none;
  }
}

.dropdown-menu {
  font-size: 14px ;

  .dropdown-item {
    padding: 0.25rem 0.75rem;
    font-weight: 500;
    cursor: pointer;
  }

  .dropdown-item:hover {
    background-color: #ecc94e ;
    outline-color: transparent;
  }
}

.seacrh-block {
  position: relative;
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
//   button {
//     border-top-left-radius: 0;
//     border-bottom-left-radius: 0;
//   }
  .search-clear {
    position: absolute;
    right: 55px;
    top: 30%;
    cursor: pointer;
    background-color: inherit;
  }
}

.add-btn {
  border-radius: 100%;
}
