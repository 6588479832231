@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap&subset=cyrillic");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700,900&display=swap&subset=cyrillic");

html {
    font-size: 16px;
}

$border-color: #d7dde2;

$primary: #1A67B4;
$danger: #fb6565;
$sidebar-color: #fff;
$sidebar-alink: #2ecc71;
$sidebar-amenu: #2c3e50;
$sidebar-width: 240px;
$outer-border: 0;




@import "reset";
@import "utils";
@import "main";

.leaflet-container{
    height:500px;
    width: 100%
}

@tailwind base;
@tailwind components;
@tailwind utilities;